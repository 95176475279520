.header {
    position: sticky;
    z-index: 1;
    top: 0;
    display: flex;
    width: 100%;
    height: 48px;
    justify-content: center;
    padding: 0 80px;
    background-color: #1aa2e1;

    &__container {
        display: flex;
        width: 100%;
        max-width: 1440px;
        box-sizing: border-box;
        align-items: center;
        justify-content: flex-start;
        padding: 0 80px;
    }

    &__title {
        display: flex;
        align-items: center;
        color: #f5f5f7;
        font-size: 14px;
        line-height: 18px;
        text-decoration: none;

        &_chevron {
            width: 24px;
            height: 24px;
            margin-right: 10px;
        }
    }

    &__logo {
        color: white;
        font-size: 23px;
        user-select: none;
    }

    &__sections {
        display: flex;
        flex: 1 1 auto;
        justify-content: center;
    }

    &__section {
        padding: 15px 30px;
        border: none;
        background-color: #1aa2e1;
        color: white;
        cursor: pointer;
        font-family: 'SF Pro Display', serif;
        font-size: 14px;
        line-height: 18px;

        &:hover {
            background-color: #31a8e9;
        }

        &:focus {
            border: none;
            background-color: #19a5e6;
        }

        &_pressed {
            background-color: #1a9ad6;
        }
    }
}

@media (max-width: 1024px) {
    .header {
        justify-content: flex-end;
        padding: 0;

        &__container {
            justify-content: space-between;
            padding: 0 16px;
        }
    }
}
