.footer {
    display: flex;
    width: 100%;
    justify-content: center;
    padding: 0 80px;
    margin-top: 80px;
    background-color: #1aa2e1;

    &__container {
        display: flex;
        width: 100%;
        max-width: 1440px;
        box-sizing: border-box;
        padding: 0 80px;
    }

    &__info {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-between;
        color: white;
        font-size: 14px;
        gap: 20px;
        line-height: 18px;
    }

    &__partners-logo {
        display: flex;
        align-items: center;
        padding: 20px 0;
        gap: 40px;

        a {
            display: flex;
        }

        .fasie {
            width: 122px;
            height: 60px;
            margin-bottom: -15px;
        }
    }
}
