@import 'variables';

.description {
    margin-top: -5px;

    &__title {
        margin-bottom: 14px;
        color: #808899;
        font-size: 36px;
        font-weight: 700;
        line-height: 42px;
    }

    &__section {
        margin-top: 34px;
    }

    &__sub-title {
        margin-bottom: 10px;
        color: #666;
        font-size: 16px;
        font-weight: 700;
        line-height: 24px;

        &_uppercase {
            text-transform: uppercase;
        }
    }

    &__more-info {
        color: #808899;
        font-size: 16px;
        line-height: 24px;
    }

    &__more-info-link {
        display: inline-block;
        margin-top: 9px;
        margin-bottom: 35px;
        color: #1aa2e1;
        font-size: 14px;
        line-height: 24px;
        text-decoration: underline;
    }

    &__text {
        color: #666;
        font-size: 16px;
        line-height: 24px;
    }

    &__cursive-text {
        color: #666;
        font-size: 16px;
        font-style: italic;
        line-height: 24px;
    }

    &__text + &__text {
        margin-top: 14px;
    }

    &__reference {
        margin-top: 14px;
        color: #808899;
        font-size: 12px;
        line-height: 16px;
    }

    &__list-title {
        margin-bottom: 16px;
        color: #666;
        font-size: 16px;
        font-weight: 700;
        line-height: 24px;
    }

    &__text + &__list,
    &__list + &__text {
        margin-top: 16px;
    }

    &__list {
        margin-left: 60px;
        color: #808899;
        list-style: unset;
    }

    &__list-item {
        margin: 8px;
        font-size: 16px;
        line-height: 24px;

        &:first-child {
            margin-top: 0;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }

    &__download-link {
        display: flex;
        width: max-content;
        height: 37px;
        align-items: center;
        justify-content: center;
        padding: 2px 13px;
        border: 1px solid #1aa2e1;
        margin-top: 25px;
        color: #1aa2e1;
        font-size: 14px;
        line-height: 16px;
        text-align: center;
        text-transform: uppercase;
    }

    &__btn-up {
        display: none;
    }
}

@media (max-width: $desktop-width) {
    .description {
        &__title {
            margin-bottom: 7px;
            font-size: 24px;
        }

        &__more-info {
            font-size: 24px;
        }

        &__more-info-link {
            margin-top: 7px;
            margin-bottom: 39px;
        }

        &__list {
            margin-left: 22px;
        }

        &__btn-up {
            position: fixed;
            right: 3%;
            bottom: 5%;
            display: block;
            border: none;
            background-color: transparent;
            border-radius: 50%;
            outline: none;
        }

        &__reference {
            margin-top: 12px;
        }

        &__section {
            margin-top: 39px;
        }
    }
}
