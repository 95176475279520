.navigation-menu {
    position: absolute;
    z-index: 100;
    top: 0;
    right: 0;
    display: flex;
    width: 183px;
    flex-direction: column;
    background: #f5f6f8;
    box-shadow: 0 0 20px #bdbdbd;

    &_item {
        width: 100%;
        padding: 10px 20px;
        border: none;
        color: #4f4f4f;
        cursor: pointer;
        font-family: 'SF Pro Display';
        font-size: 16px;
        line-height: 19px;
        text-align: left;

        &:hover &:focus {
            background-color: #1aa2e1;
            color: white;
        }
    }

    &_icon {
        width: 24px;
        height: 24px;
        padding: 10px 0;
        border: none;
        background-color: transparent;
        cursor: pointer;
    }
}
