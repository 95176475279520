@import 'variables';

.data-plexus-table {
    display: grid;
    margin-top: 40px;
    color: #666;
    gap: 20px 15px;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(4, 1fr);

    &__title {
        font-weight: 700;
    }

    &__item {
        display: list-item;
        margin-left: 45px;
        color: #808899;
        list-style-type: disc;
    }
}

@media (max-width: $desktop-width) {
    .data-plexus-table {
        gap: 10px 15px;
        grid-template-rows: 1fr repeat(3, 2fr);

        &__item {
            margin-left: 20px;
        }
    }
}
