@import 'variables';

.products {
    max-width: 1440px;
    padding-top: 40px;
    margin: auto;

    &__section {
        margin-top: 34px;
        margin-bottom: 32px;
    }

    &__title {
        position: relative;
        display: flex;
        align-items: center;
        margin-bottom: 55px;
        margin-left: 10px;
        color: #474766;
        font-size: 40px;
        font-weight: 700;
        line-height: 42px;

        &::before {
            position: relative;
            display: inline-block;
            width: 8px;
            height: 48px;
            margin-right: 25px;
            background: rgb(26 162 225 / 80%);
            border-radius: 2px;
            content: '';
        }
    }

    &__page {
        display: grid;
        margin: 0 10px;
        gap: 20px;
        grid-template-columns: 1fr 2fr;
    }

    &__list {
        &::after {
            display: block;
            width: 20%;
            height: 1px;
            border: none;
            margin: 20px 0;
            background-color: #d9d9d9;
            content: '';
        }

        &:last-child::after {
            content: none;
        }
    }

    &__item {
        margin-top: 20px;
        margin-bottom: 20px;

        &:first-child {
            margin-top: 0;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }

    &__link {
        color: #474766;
        font-size: 18px;
        line-height: 26px;

        &:hover {
            color: #41ade9;
        }

        &:focus {
            color: #4dbbed;
        }

        &:active {
            color: #1c99d2;
        }

        &.active {
            color: #1aa2e1;
            font-size: 18px;
            line-height: 26px;
        }
    }

    &__line {
        width: 20%;
        height: 1px;
        border: none;
        margin: 20px 0;
        background-color: #d9d9d9;
    }
}

@media (max-width: $desktop-width) {
    .products {
        &__title {
            font-size: 30px;

            &::before {
                height: 40px;
                margin-right: 8px;
            }
        }

        &__page {
            display: block;
            margin: 0 15px;
        }

        &__line {
            width: 35%;
        }
    }
}
