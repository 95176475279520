@import 'variables';

.voice-capture-box {
    margin-top: 34px;
    margin-bottom: 48px;
    color: #666;

    &__title {
        margin-bottom: 28px;
        color: #666;
        font-size: 16px;
        font-weight: 700;
        line-height: 24px;
    }

    &__variables-table {
        display: grid;
        align-items: baseline;
        color: #666;
        gap: 40px;
        grid-template-columns: 3fr 3fr 4fr 2fr;
        grid-template-rows: repeat(3, 1fr);

        &_mobile {
            display: none;
        }
    }

    &__variables-table-head {
        align-items: flex-end;
        font-weight: 700;
    }

    &__variables-table-body {
        align-items: flex-end;
    }

    &_bold {
        font-weight: 700;
    }

    &__reference {
        margin-top: 40px;
        font-size: 16px;
    }

    &__stats-table {
        display: grid;
        grid-template-columns: 2fr 3fr;
        row-gap: 25px;
    }

    &__complect-list {
        margin-left: 29px;
    }

    &__complect-item {
        margin-bottom: 24px;
        list-style-type: '—  ';

        &:last-child {
            margin-bottom: 0;
        }
    }
}

@media (max-width: $desktop-width) {
    .voice-capture-box {
        position: relative;
        font-size: 14px;

        &__block {
            position: relative;
        }

        &__block + &__block {
            margin-top: 35px;
        }

        &__section {
            margin: 15px 0;
        }

        &__sub-title {
            margin-bottom: 10px;
            font-weight: 700;
        }

        &__text {
            line-height: 24px;
        }

        &__title {
            margin-bottom: 16px;
        }

        &__variables-table {
            display: none;

            &_mobile {
                display: block;
            }
        }

        &_bold {
            display: none;
        }

        &__stats-table {
            column-gap: 25px;
        }
    }
}
