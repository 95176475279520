@import 'styles/app';
@import 'styles/products';
@import 'styles/data-plexus';
@import 'styles/voice-capture-box';
@import 'styles/description';
@import 'styles/header';
@import 'styles/burger';
@import 'styles/main';
@import 'styles/footer';
@import 'styles/variables';

@font-face {
    font-family: 'SF Pro Display';
    font-style: normal;
    font-weight: normal;
    src: url('./fonts/SFProDisplay-Regular.eot');
    src:
        local('SF Pro Display Regular'),
        local('SFProDisplay-Regular'),
        url('./fonts/SFProDisplay-Regular.eot?#iefix') format('embedded-opentype'),
        url('./fonts/SFProDisplay-Regular.woff2') format('woff2'),
        url('./fonts/SFProDisplay-Regular.woff') format('woff'),
        url('./fonts/SFProDisplay-Regular.ttf') format('truetype');
}

html {
    font-family: 'SF Pro Display', sans-serif;
    overflow-x: hidden;
    scroll-behavior: smooth;
}

body {
    min-width: 375px;
}

a {
    color: #1aa2e1;
    text-decoration: none;
}

b {
    font-weight: bold;
}
