.page {
    display: flex;
    max-width: 1440px;
    box-sizing: border-box;
    flex-direction: column;
    padding: 130px 80px 80px;

    &__title {
        width: 70%;
        color: #474766;
        font-size: 48px;
        font-weight: 700;
        line-height: 56px;
    }

    &__subtitle {
        width: 70%;
        margin-top: 35px;
        color: #808899;
        font-size: 24px;
        line-height: 32px;
    }
}

.image-wave {
    z-index: -1;
    width: 100%;
    align-self: center;
    margin: -120px 0 100px;
    transform: matrix(0.94, -0.35, 0, 1.06, 0, -30);
    user-select: none;
}

// .button {
//     padding: 15px 30px;
//     border: none;
//     color: white;
//     font-size: 14px;
//     line-height: 18px;
//     text-transform: uppercase;
//     background-color: #1AA2E1;
//     cursor: pointer;
//     margin-top: 75px;
//     max-width: 187px;

//     &:hover {
//         background-color: #31A8E9;
//     }

//     &:focus {
//         background-color: #19A5E6;
//         border: none;
//     }
// }

.sections {
    display: flex;
    flex-direction: column;
    gap: 165px;
}

.section {
    display: flex;
    flex-direction: column;

    &__header {
        display: flex;
        align-items: center;
    }

    &__header-line {
        width: 8px;
        height: 48px;
        margin-right: 24px;
        background-color: rgb(26 162 225 / 80%);
        border-radius: 2px;
    }

    &__header-title {
        color: #474766;
        font-size: 40px;
        font-weight: 700;
        line-height: 42px;
    }

    &__body {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 55px;
    }
}

.company-indicators {
    display: grid;
    width: 100%;
    margin-bottom: 47px;
    column-gap: 120px;
    grid-template-columns: repeat(4, 1fr);
}

.company-indicator {
    display: flex;
    flex-direction: column;
    align-items: center;

    &__figure {
        margin-bottom: 23px;
        color: #1aa2e1;
        font-size: 36px;
        font-weight: 700;
        line-height: 40px;
    }

    &__description {
        color: #474766;
        font-size: 18px;
        line-height: 24px;
        text-align: center;
    }
}

.company-description {
    max-width: 80%;

    &__paragraph {
        margin-bottom: 21px;
        color: #474766;
        font-size: 18px;
        line-height: 40px;
        list-style: disc;

        &:last-child {
            margin-bottom: 0;
        }
    }
}

.company-partners {
    display: flex;
    width: 100%;
    justify-content: center;
    gap: 140px;
}

.company-partner {
    width: 100%;

    &__logo {
        margin-bottom: 37px;
        user-select: none;
    }

    &__description {
        margin-bottom: 13px;
        color: #666;
        font-size: 18px;
        line-height: 40px;
    }

    &__link {
        color: #1aa2e1;
        font-size: 18px;
        line-height: 40px;
    }
}

.company-contacts {
    align-self: start;
    margin: 0 57px 36px;
}

.company-contact {
    color: #808899;
    font-size: 16px;
    line-height: 24px;

    &__header {
        color: #666;
        font-size: 18px;
        font-weight: 700;
        line-height: 32px;
    }
}

.map {
    max-width: 80%;
}

.button-up {
    position: fixed;
    right: 40px;
    bottom: 80px;
    display: block;
    border: none;
    background-color: transparent;
    cursor: pointer;
    user-select: none;
}

@media (min-width: 768px) {
    .section {
        &_row {
            flex-direction: row;
            justify-content: space-between;
        }
    }
}

@media (max-width: 1024px) {
    .page {
        padding: 65px 16px 0;
    }

    .sections {
        gap: 80px;
    }

    .company-indicators {
        margin-bottom: 42px;
        column-gap: 60px;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(2, 1fr);
        row-gap: 33px;
    }

    .company-indicator {
        display: flex;
        flex-direction: column;
        align-items: center;

        &__figure {
            margin-bottom: 5px;
            font-size: 30px;
        }
    }

    .company-contacts {
        margin: 0 0 36px;
    }
}

@media (max-width: 768px) {
    .page {
        &__title {
            font-size: 32px;
            line-height: 40px;
        }

        &__subtitle {
            margin-top: 30px;
            font-size: 18px;
            line-height: 24px;
        }
    }

    .image-wave {
        width: 60%;
        transform: matrix(0.94, -1.35, 1, 1.06, 0, 80);
    }

    .section {
        padding: 0;

        &_first {
            padding-top: 100px;
        }

        &__header-line {
            height: 40px;
            margin-right: 8px;
        }

        &__header-title {
            font-size: 30px;
        }

        &__body {
            padding-top: 33px;
        }
    }

    .company-description {
        &__paragraph {
            margin-bottom: 26px;
            line-height: 32px;
        }
    }

    .company-partners {
        flex-direction: column;
        align-items: center;
        gap: 50px;
    }

    .company-partner {
        &__logo {
            margin-bottom: 27px;
        }

        &__description {
            margin-bottom: 21px;
            line-height: 32px;
        }
    }

    .map {
        width: 100%;
        max-width: 100%;
    }
}

@media (max-width: 425px) {
    .page {
        &__title {
            font-size: 32px;
            line-height: 40px;
        }

        &__subtitle {
            margin-top: 30px;
            font-size: 18px;
            line-height: 24px;
        }
    }

    .image-wave {
        width: 90%;
        margin: -200px 0 100px;
        transform: matrix(0.94, -1.35, 1, 1.06, 0, 40);
    }

    .map {
        height: 282px;
    }
}
